@import url('./font.css');

* {
  box-sizing: border-box;
  outline: none;
}

/* *********************************************************Resident******************************************** */
/* element.style {
}
.label-color {
    color: #909090;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}

.font-medium {
    font-weight: 500;
}
.block {
    display: block;
}
* {
    box-sizing: border-box;
} */

.col-span-4{
  width:100%;
  margin-top: 10px;
  /* font-weight: bold; */
}
.bg-table-header {
  background-color: #43425D;
  color:white
}


.table-border-right{
  border-right:2px solid #0000000A ; 
  
  /* 1px ​solid #d0d0d0 */
}


.bg-card-header{
  background-color: #d5ffd5;
  margin: 0;
  cursor: pointer;
}

.col{
  border-bottom:1px solid #C7C7C7

}

.flex-between{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.p-2, .px-3, .px-6, .px-4{
  padding: 10px;
}
.colorW{
  background-color: white;
}
.border-bot{
  border-bottom: 2px solid blue;
}
.w-40{
  width: 40%;
}
.w-30{
  width: 30%;
}
.w-60{
  width: 60%;
}

.flex-align{
  display: flex;
  align-items: center;
}
.flex-align-top{
  display: flex;
  align-items: top;
}
.mFiles-hover:hover{
  background-color: gainsboro;
}

.page1_input{
  display:flex;
   flex-direction:column; 
   justify-content:center

}
.right-border{
  border-right: 1px solid ;

}
.centralize{
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageResidency{
  /* border:2px solid red; */
  border-radius: 50%;
  width: 120px;
 height: 120px;
   background-color:white ;
}
.contactUs:hover{
  color:#25d366;
  font-family: sans-serif;
  font-weight: bold;
}

.ant-upload-select-picture-card{
  border-radius: 100% !important;
  max-width: 140px !important;
  max-height: 140px !important;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 100% !important;
  max-width: 140px !important;
  max-height: 140px !important;
  border:none !important;

}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
 border-radius: 100% !important;
  /* width: 140px !important; */
  /* height: 140px !important; */
  border:none !important;
}
.slipId{
  
  width:900px;
  /* border: 2px solid red; */

}
.banner b{
  
    /* font-size: 1.58rem; */
    font-family: sans-serif;
    /* line-height: 1.5rem; */
    font-weight: 600;
}
.headings{
  display:flex;
  align-items: center;
  justify-content: space-between;
  /* width:900px; */

}
.m-10{
  margin: 10px 0;
}
.w-30{
  width:30%
}
.w-40{
  width:40%
}
.w-22{
  width:22%
}
.page12 p, .page12 h4{
  margin-bottom: 0 !important;
  
}
.circles{
  width: 30px;
  height: 30px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
}

.mx-auto{
margin: auto;
}

.flex-1{
  width:100%
}

.w-6{
width:60%
}

.w-4{
  width:60%
  }
  

 

.opacity-30{
  opacity: 0.3;
}


* {
  box-sizing: border-box;
  font-family: avenir;
}* {
  margin: 0;
  padding:0;
}
.enlarge:hover{
font-size: 24px;
background-color: rgb(163, 163, 241);
}

.ant-modal>.ant-modal-content{
  background-color: transparent;
  border: none;
}
.ant-modal-body{
  background-color: none;
}
.page30{
  background-color: transparent;
}

/* h1, h2, h3, h4, h5, h6, p,
div, header, section{
  margin: 0;
  margin-bottom: 0;
  padding: 0;
} */
.flex-between{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.side p{
  font-size: 11.5px !important;
}
.side .ant-form-item-required{
  color: #909090;
  font-size: 13px;
}
.page5F .ant-form-item-required{
  color: #909090;
  font-size: 13px;
}
.marginBottom{
margin-bottom: 10px;
}
.border{
  border: 2px solid #0000000A;
}
.table-border-bottom{
  border-bottom: 2px solid #0000000A;

}
.background{
  background-color: #F5F7F9;
}
.padding{
  padding:10px;
}
.flex{
  display: flex;
}
.flex-align{
  display: flex;
  align-items: center;
}
.flex-align-top{
  display: flex;
  align-items: top;
}
/* .position{
  
  position: absolute;
right:0;
top:50%;
} */
.position{
  /* float: right; */
  /* border: 2px solid red; */
  position: relative;
right:15px;
bottom:50px;
}

/* .page44 p{
  margin:0;
  padding: 0;
} */
/* .check{
  margin-bottom: 0;
  padding-bottom: 0;
} */
.page13 p, .page13 h2, .page13 h3, .page13 h4{
margin-bottom: 0;
}
.dependant{
font-size: 11px;
opacity: 0.4;
}

.colorW{
  background-color: white;
}

.page21 .ant-table>.ant-table-thead>tr>th {
  background-color: #BCBCBC;
font-weight: bold;
  color:white
}
.page26 .ant-table .ant-table-thead>tr>th, .page34 .ant-table .ant-table-thead>tr>th {
  background-color: #43425D;
  color:white
}
.ant-form .ant-select {
  
  font-size: 13px;
}
.ant-form div{
  font-size: 13px;
}
.ant-input-affix-wrapper>input[type=text] {
  background-color: #D5FFD5;
 
}
.app .ant-input-affix-wrapper{
  padding: 8px 11px;
}
/* .devTrain12 .ant-input-affix-wrapper>input[type=text] {
  background-color: #D5FFD5;
} */
.inAnalytics .ant-input-affix-wrapper>input[type=text] {
  background-color: #ffffff;
}
.bold{
  font-family: sans-serif;
  font-weight: bold;
}
.opacity{
  opacity:0.4
}
.ant-row, .ant-form-item{
width: 100%;
row-gap: 10px;
}
.page5 .ant-upload{
  background-color:#EFD66BF2;
border-top-right-radius: 10px;
}
.itemRequest .ant-upload{
  /* width: 45%; */
  border-top-right-radius: 10px;
  }
  
   .ant-form-item-label label{
    color:#909090
  }
.flex-between{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.flex{
  display: flex;
}
.flex-align{
  display: flex;
  align-items: center;
}
.flex-align-top{
  display: flex;
  align-items: top;
}
.mFiles-hover:hover{
  background-color: gainsboro;
}
/* .page44 p{
  margin:0;
  padding: 0;
} */
/* .check{
  margin-bottom: 0;
  padding-bottom: 0;
} */

.ant-table .ant-table-thead>tr>th {
  background-color: #43425D;
  font-size: 13px;
  color:white;
  font-weight: bold;
}
.ant-table .ant-table-thead>tr{
  background-color: #43425D;
  font-size: 13px;
  color:white;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.page12 .ant-btn{
  font-size: 13px;
  padding: 2px 15px
}
.app .ant-btn{
  height:40px;
  font-size: 13px;
  /* background-color: #017D34; */
  /* background-color: #EFEFEF; */
  background-color: #1C811C;
  color:white;
  border-radius: 7px;

  /* padding: 0 ; */
  border-color: transparent;
}
.btn-gr{
 
    background-color: #1C811C;
    color:white;
  min-width: 50px;
    /* padding: 0 ; */
  
}
.bg-green{
    background-color: #1C811C;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
}
.bg-yellow{
  background-color: yellow;
    border-radius: 50%;
    margin-right: 5px;
    height: 15px;
    width: 15px;
}
/* .app .ant-btn:hover{
  color: blue;
} */
.page6 .ant-btn > span:hover{
  color: blue;
}

/* ********************************************************************ANTD********************************* */
/* Page4 */
/* .ant-col .ant-form-item-control:focus, .ant-form-item-control-input:focus,.ant-form-item-control-input-content:focus{
  border:none
} */

 /* .ant-form-item-control-input-content, .ant-form-item-control-input-content :active{
  margin:5px;
  border:none
} */
.page4 .ant-input{
  border:none;
  border-bottom: 3px solid;
  
 
} 
.page4 .ant-form-item-control-input-content>input[type='text']{ 
 /* text-align: center; */
   font-family: sans-serif;
font-weight: "bolder";
font-size: 24px;
letter-spacing: 40px;
padding-left: 20px;
}
/* .inp.ant-form-item-control-input>.ant-input:hover{
  border:none;;
  border-bottom: 3px solid blue;
  margin:5px;
 
} */


/* .ant-input-focus .ant-input-selector,
.ant-input-selector:focus,
.ant-input-selector:active,
.ant-input-open .ant-input-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
} */
 .page4 .ant-input:focus{
  box-shadow: none !important;
  border:none;
  border-bottom: 3px solid  #017D34;
  box-shadow: none;
}
.app .ant-btn:hover{
  background-color: #017D34;
  color: white;

}
.page4 a, .under a{
  text-decoration: underline;
}


/* Page 5 */

.page5 .ant-form-item{
  margin: 0px;
}
.page5 .input[type=text]
{
  line-height: 13px;
}
.page5 .ant-select{
  line-height: 3px;
}
.m-10{
margin:7px 0
}

.page5 .ant-form-item-control-input-content>input[type='text']{ 
  /* text-align: center; */
    /* font-family: sans-serif; */
 /* font-weight: "bolder"; */
 /* font-size: 24px; */
 /* letter-spacing: 40px; */
 padding-left: 20px;
 }
 .page5 .ant-input{
  border:none;
  border-bottom: 1px solid #C6C6C6;
  
 
} 
 .page5 .ant-input:focus{
  box-shadow: none !important;
  border:none;
  border-bottom: 3px solid  #017D34;
  /* box-shadow: none; */
}
.page5 .ant-input:hover{
  box-shadow: none !important;
  border:none;
  border-bottom: 3px solid  #017D34;
  /* box-shadow: none; */
}

 
/* PAGE6 */
.pad-20{
padding:20px;
}
/* ********************************************************************ANTD********************************* */

/* *********************************************************Resident******************************************** */

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p,
div, header, section {
  margin: 0;
}

p{
  margin: 0 !important;
}
/* .bg-gray-table-header{
  background-color: grey;
} */
.summary:hover{
  color:green
}

select{
  /* background-color: #1C811C; */
  padding:10px !important;
  min-width: 200px;
  /* border-radius: 50px; */

}

/* .divide-y, .divide-gray-200{
  padding:"10px"
} */

p, textarea {
  white-space: pre-line;
}
.btt, .primary-bg-green{
  background-color: #1d811c;
  color: white;
  cursor: pointer;
}


button, input {
  border: none;
  outline: none;
}

section {
  width: 100%;
  margin: 0;
}

.p-r {
  position: relative!important;
}
.absolute {
  position: absolute!important;
}
/* .grid-cols-1{
  display: flex;
  justify-content: space-between;
} */
.col-span-3{
  width: 100%;
  margin-right: 10px;
}


.no-padding {
  padding: 0!important;
}
.p-5,.p-6,.p-3, .px-2, .py-4, .p-4 {
  padding: 5px!important;
}
.p-10,.px-4, .py-2,.px-3,.py-3 {
  padding: 10px!important;
}
.p-20 {
  padding: 20px!important;
}
.p-40 {
  padding: 40px!important;
}
.p-t-40 {
  padding-top: 40px!important;
}
.p-r-5 {
  padding-right: 5px!important;
}
.p-l-5 {
  padding-left: 5px!important;
}

.no-margin {
  margin: 0!important;
}
.margin-center {
  margin: 0 auto!important;
}
.m-l-5 {
  margin-left: 5px!important;
}
.m-l-10 {
  margin-left: 10px!important;
}
.m-l-20 {
  margin-left: 20px!important;
}
.m-r-5 {
  margin-right: 5px!important;
}
.m-r-10 {
  margin-right: 10px!important;
}
.m-t-10,.mt-3, .col-span-12 {
  margin-top: 10px!important;
}
.m-t-20 {
  margin-top: 20px!important;
}
.m-t--20 {
  margin-top: -20px!important;
}
.m-t-40 {
  margin-top: 40px!important;
}

.m-t-5,.mt-5 {
  margin-top: 5px!important;
}
.m-b-5,.mt-1 {
  margin-bottom: 5px!important;
}
.m-b-10 {
  margin-bottom: 10px!important;
}
.m-b-20 {
  margin-bottom: 20px!important;
}
.m-b-40 {
  margin-bottom: 40px!important;
}
.m-b-50 {
  margin-bottom: 50px!important;
}

.no-border {
  border: none!important;
}
.border, .table-border-right {
  border: 1px solid #C6C6C6!important;
}
.bottom-border {
  border-bottom: 1px solid #C6C6C6!important;
}
.box-shadow {
  box-shadow: 0px 2px 6px #0000000A!important;
}
.no-wrap {
  flex-wrap: nowrap!important;
}
.wrap {
  flex-wrap: wrap!important;
}
.flex {
  display: flex!important;
}
.flex-h-center {
  justify-content: center!important;
}
.flex-v-center {
  align-items: center!important;
}
.pointer, .cursor-pointer {
  cursor: pointer!important;
}
.underline {
  text-decoration: underline!important;
}
.center-text {
  text-align: center!important;
}
.right-text {
  text-align: right!important;
}
.space-between {
  justify-content: space-between!important;
}
.flex-end {
  justify-content: flex-end!important;
}
.flex-v-baseline {
  align-items: baseline!important;
}
.float-right {
  float: right!important;
}
.inline-block {
  display: inline-block!important;
}
.bold-text {
  font-weight: bold!important;
}

.max-w-400 {
  max-width: 400px!important;
}
.max-w-600 {
  max-width: 600px!important;
}
.max-w-800 {
  max-width: 800px!important;
}

.col-1, .col-2, .col-3, .col-4,
.col-5, .col-6, .col-half, .col-7, .col-8,
.col-9, .col-10, .col-11, .col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6, .col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12, .w-100 {
  width: 100%!important;
  position: relative;
}
 .w-95 {
  width: 49%!important;
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.bg-white { background-color: #ffffff!important; }
.bg-gray { background-color: #dddddd!important; }
.bg-light-gray { background-color: #F5F7F9!important; }

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  /* background-image: url('../images/banner.png'); */
  /* background-color: #1C811C55; */
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border:1px solid #C6C6C6;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #C6C6C6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
  /* letter-spacing: 5px; */
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
}
.login-form-groupz {
  /* width: 100%; */
  /* padding: 13px; */
  /* border: 1px solid #c6c6c6; */
  /* border-radius: 5px; */
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* font-size: 0.9rem; */
  /* padding: 13px 0;
  color: #777;
  position: absolute; */
  /* top:13px; */
  /* right: 0; */
  /* margin:0 13px */
cursor: pointer;
  width: 10%;
  height: 100%;
  padding: 13px 0;
  position: absolute;
  right:0;
  top: 0px;
  border:none ;
   display: flex;
   align-items: center;
   opacity:"0.3";
  /* border: 1px solid #c6c6c6; */
  /* border-radius: 5px; */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.9rem;
  color: #777;


}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017D34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017D3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #FFFFFF;
  box-shadow: 5px 3px 22px #958E8E29;
  border: 1px solid #C6C6C6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1C811C;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #C6C6C6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1C811C1F;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #C6C6C6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1C811C;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px!important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}
