.popho:hover{
    background-color: #D5FFD5;
    cursor: pointer;
    color: black;
}
.strn:hover{
    background-color: #D5FFD5;
    cursor: pointer;
    color: black;
    border: 1px solid lightgray;
    height: 30px;
    padding-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    
   
}

.dsp{
    
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px;
      
}

.d-none{
display: none;
}
.d.true{
    display: flex;
}
